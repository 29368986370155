import React from 'react';
import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import loadable from '@loadable/component';

import { URL } from 'src/resources/constants/url';

const ScreenContainer = loadable(() => import('src/components/screenContainer'));

// wrap <Route> and use this everywhere instead, then when
// sub routes are added to any route it'll work
function RouteWithSubRoutes(props) {
  const { component: Component, routes, path, componentProps, containerProps, ...rest } = props;
  const depositOrderInfo = useSelector(state => state.deposit?.orderInfo);
  return (
    <Route
      {...rest}
      path={path}
      render={props => {
        // pass the sub-routes down to keep nesting
        const COM = <Component {...props} {...componentProps} routes={routes} />;
        let overwriteProps = {};
        if ((path.includes(URL.DEPOSIT_FIAT) || path.includes(URL.INVEST)) && !!depositOrderInfo) {
          overwriteProps = { header: false, footer: false };
        }
        return <ScreenContainer {...containerProps} {...overwriteProps}>{COM}</ScreenContainer>;
      }}
    />
  );
}

export default RouteWithSubRoutes;
